import * as React from "react"

export default class ZnanyhLekarz extends React.Component {
  
    constructor(props) {
      super(props);
      this.doctor = this.props.doctor
      this.doctor = this.props.doctor
    }

    componentDidMount() {
      var js,fjs=document.getElementsByTagName("script")[0];
            
      if (!document.getElementById("zl-widget-s")) {
          js = document.createElement("script");
          
          js.id = "zl-widget-s";
          js.src = "//platform.docplanner.com/js/widget.js";
          
          fjs.parentNode.insertBefore(js,fjs);
      }
    }
  
    render() {
      return (
        <a id="zl-url" className="zl-url" 
            href={this.props.url}
            rel="nofollow" 
            data-zlw-doctor={this.props.doctor} 
            data-zlw-type={this.props.type}
            data-zlw-opinion="false" 
            data-zlw-hide-branding="true">{this.props.label}</a>
      );
    }
}
