import * as React from "react"

import Layout from "../components/layout"
import ZnanyLekarz from "../components/ZnanyLekarz"

import mariaczubswierczek from "../images/doctor.png"



const IndexPage = () => {
  return (
    <Layout>
        <div className="site-content-contain">
          <div className="site-content-wrap">
              <div className="site-content container">
                  <div className="content-area ">
                      <main className="site-main">
                          <article className="container dsvy-team-single-style-1 post-12023 dsvy-team-member type-dsvy-team-member status-publish has-post-thumbnail hentry">
                              <div className="dsvy-team-single">
                                  <div className="dsvy-team-single-inner">
                                      <div className="row">
                                          <div className="col-md-5 col-lg-4">
                                              <div className="dsvy-team-left-inner">
                                                  <div className="dsvy-featured-wrapper">
                                                      <img width="800" height="800"
                                                          src={mariaczubswierczek}
                                                          className="attachment-full size-full wp-post-image"
                                                          alt="Chirurg onkolog Maria Czub-Świerczek"
                                                          sizes="(max-width: 800px) 100vw, 800px" />
                                                  </div>
                                                  <div className="dsvy-team-summary">
                                                      <h2 className="dsvy-team-title">lek. Maria Czub-Świerczek</h2>
                                                      <h4 className="dsvy-team-designation">Chirurg onkolog</h4>
                                                  </div>
                                                  
                                                  <div className="dsvy-team-member-details-apply">
                                                    <a href="tel:618-610-123">Umów wizytę: <i className="dsvy-base-icon-call-1"></i>618-610-123</a>
                                                  </div>
                                                  
                                                  <ZnanyLekarz 
                                                    doctor="maria-czub-swierczek-2" 
                                                    type="big_with_calendar" 
                                                    label="Chirurg onkolog Maria Czub-Świerczek - ZnanyLekarz.pl"
                                                    url="https://www.znanylekarz.pl/maria-czub-swierczek-2/onkolog-chirurg/poznan"
                                                  />
                                                  

                                              </div>
                                          </div>
                                          <div className="col-md-7 col-lg-8">
                                              <div className="dsvy-short-description">
                                                  <h3>O mnie</h3>
                                                  <p>
                                                    
                                                  </p>
                                                  
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </article>
                      </main>
                  </div>
              </div>
          </div>

      </div>

    </Layout>          
  )
}

export default IndexPage
